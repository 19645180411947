<template>
    <div class="px-2 py-1">{{country}}</div>
</template>

<script>
import {COUNTRY_FULL_NAME} from "../../constants";

export default {
    name: "base-column-country",
    props: ['column', 'item'],
    computed: {
        country(){
            const countryCode = this.$props.item[this.$props.column]
            return COUNTRY_FULL_NAME(countryCode)
        }
    },
}
</script>

<style scoped>

</style>