import {find} from "lodash";
import Vue from "vue";

const currentLanguage = Vue.config.language;


export const INFO_BOX_TIMEOUT = 5000; // in ms

export const COUNTRIES = [
    {
        code: 'BE',
        country: {
            'nl': "Belgie",
            'en': "Belgium",
            'fr': "Belgium",
        }
    },
    {
        code: 'NL',
        country: {
            'nl': "Nederland",
            'en': "Netherlands",
            'fr': "Nederland",
        }
    },
    {
        code: 'FR',
        country: {
            'nl': "Frankrijk",
            'en': "France",
            'fr': "Frankrijk",
        }
    }
]

export const COUNTRY_FULL_NAME = (code) => {
    const country_item = find(COUNTRIES, c => c.code === code);
    return country_item ? country_item.country[currentLanguage] : code
}

export const APPLICATIONS = {
    'MANAGER': 1,
    'SALES': 2,
    "LEVERANCIERS": 3
}

export const USER_TITLES = ['Mr', 'Mrs', 'Miss', 'Mx'];

export const HOME_LIST_ITEMS_PER_PAGE = 25;

export const MENU_KEYS = {
    'GET_STARTED': 'home',
    'MESSAGES': 'messagesHome',
    'NEWS': 'newsHome',
    'COMPANY': 'myCompanyHome',
    'SETTINGS': 'mySettingsHome',
    'SUPPLIERS': 'mySuppliersHome'
}

export const DASHBOARD_KEYS = {
    "GET_STARTED": 'get_started',
    "MESSAGE": 'message',
    "NEWS_CONTENT": 'news_content',
    "COMPANY_GENERAL": 'company_general',
    "COMPANY_ADDRESSES": 'company_addresses',
    "COMPANY_USERS": 'company_users',
    "COMPANY_BUSINESS_UNITS": 'company_business_units',
    "COMPANY_BUSINESS_UNIT_GROUPS": 'company_business_unit_groups',
    "SUPPLIERS_LIST": 'suppliers_list',
    "SUPPLIERS_ADDRESSES": 'suppliers_addresses',
    "SUPPLIERS_USERS": 'suppliers_users',
    "SETTINGS_INFO": 'settings_info',
    "SETTINGS_PASSWORD": 'settings_password',
    "SETTINGS_2_FACTOR": 'settings_2_factor',
}

export const DETAIL_PANEL_KEYS = {
    'EDIT_COMPANY_ADDRESS': 'edit-company-address',
    'EDIT_COMPANY_USER': 'edit-company-user',
    'EDIT_BUSINESS_UNIT': 'edit-business-unit',
    'EDIT_GROUP': 'edit-group',
    'SEND_INVITATIONS': 'send-invitations',
    'EDIT_SUPPLIER': 'edit-supplier',
    'NEW_MESSAGE': 'new-message',
}

export const INBOX_KEYS = {
    'INBOX': 'inbox',
    'DELETED': 'deleted'
}



